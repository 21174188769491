<template>
  <div class="loan-application-form-builder">
    <div class="container fluid">
      <div class="card p-3">
        <h2 class="mb-4">Loan Application Form Builder</h2>

        <div class="la-form-categories">
          <h4 class="mb-2">Categories</h4>

          <div v-if="categories.length > 0">
            <div
              class="la-form-category p-2 mb-2"
              v-for="category in categories"
              :key="category.id"
            >
              <span>{{ category.name }}</span>
              <span class="ml-2">
                <button
                  type="button"
                  class="btn main bordered main"
                  @click="editCategory(category.id)"
                >
                  <i class="fas fa-pen mr-1"></i>Edit
                </button>
                <button
                  type="button"
                  class="btn main bordered danger ml-1"
                  @click="deleteCategory(category)"
                >
                  <i class="fas fa-trash mr-1"></i>Delete
                </button>
              </span>
            </div>
          </div>

          <div v-else>
            <no-data message="There is no category, please add one."></no-data>
          </div>
        </div>

        <div class="mt-3">
          <button class="btn main medium" @click="addCategory">
            <i class="fas fa-plus mr-1"></i>Add Category
          </button>
          <button
            class="btn main bordered medium ml-1"
            @click="additionalDocModal.isShown = true"
          >
            Additional Documents
          </button>
        </div>
      </div>
    </div>

    <!-- ==================== Modal: Add/Edit Category ===================== -->
    <modal v-model="categoryEditorModal.isShown" fullscreen>
      <category-builder
        :mode="categoryEditorModal.mode"
        :categoryId="categoryEditorModal.idToEdit"
        @cancel="categoryEditorModal.isShown = false"
        @create="createCategory"
        @update="updateCategory"
      ></category-builder>
    </modal>
    <!-- ================= Modal: Additional Document ================== -->
    <modal v-model="additionalDocModal.isShown" fullscreen>
      <additional-doc-builder
        @cancel="additionalDocModal.isShown = false"
        @update="updateAdditionalDoc"
      ></additional-doc-builder>
    </modal>
  </div>
</template>

<script>
import { loanApplicationDocForm as LADocFormAPI } from "@/api";

const MODE = {
  ADD: "add",
  EDIT: "edit"
};

export default {
  components: {
    CategoryBuilder: () =>
      import("@/components/LoanApplicationFormBuilder/CategoryBuilder"),
    AdditionalDocBuilder: () =>
      import(
        "@/components/LoanApplicationFormBuilder/AdditionalDocumentBuilder"
      )
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      categories: [],

      categoryEditorModal: {
        mode: MODE.ADD,
        idToEdit: "",
        isShown: false
      },
      additionalDocModal: {
        isShown: false
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        await this.getCategories();

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },
    addCategory() {
      this.categoryEditorModal.mode = MODE.ADD;
      this.categoryEditorModal.isShown = true;
    },
    editCategory(id) {
      this.categoryEditorModal.mode = MODE.EDIT;
      this.categoryEditorModal.idToEdit = id;
      this.categoryEditorModal.isShown = true;
    },

    async getCategories() {
      try {
        let data = await LADocFormAPI.getLADocFormCategories();
        this.categories = this._.cloneDeep(data.data);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get categories data. Please try again later."
        });
        throw error;
      }
    },
    async createCategory(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await LADocFormAPI.createLADocFormCategory(payload);
        this.categoryEditorModal.isShown = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Document category and field(s) has been created successfully."
        });
        await this.getCategories();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to create category and document fields. Please try again later."
        });
      }
    },

    async updateCategory(id, payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await LADocFormAPI.updateLADocFormCategory(id, payload);
        this.categoryEditorModal.isShown = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Document category and field(s) has been updated successfully."
        });
        await this.getCategories();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update category and document fields. Please try again later."
        });
      }
    },

    async deleteCategory(category) {
      let confirm = await this.$confirm({
        type: "alert",
        title: "Delete Category",
        message: `Are you sure you want to delete the category "${category.name}"? This action cannot be undone.`
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);

          await LADocFormAPI.deleteLADocFormCategory(category.id);

          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Category has been deleted successfully."
          });

          await this.getCategories();

          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to delete the category. Please try again later."
          });
        }
      }
    },

    async updateAdditionalDoc(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await LADocFormAPI.updateLADocAdditionalField(payload);
        this.additionalDocModal.isShown = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Additional Documents has been updated successfully."
        });
        await this.getCategories();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        console.log(error);
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update additional documents. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss">
.loan-application-form-builder {
  .la-form-category {
    border: solid 1px #ccc;
    border-radius: 5px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
